































































import { mapActions, mapGetters } from "vuex";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Filters from "./Filters.vue";
import InfoElements from "../InfoElements.vue";
import { defineComponent, ref } from "@vue/composition-api";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/tableJournalHeaders";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import { convertDataItemToTableView } from "@monorepo/monitoring/src/views/SyncLogOik/utils/convertDataItemToTableView";
import { ISyncLogElement } from "@monorepo/monitoring/src/views/SyncLogOik/types/syncLogElement";

export interface IData {
  isSelectAllElement: boolean;
  tableHeaders: Record<string, string | number | boolean | undefined>[];
  convertDataItemToTableView: (item: ISyncLogElement) => ISyncLogElement;
  keyFieldsArray: string[];
}

const SECTION_NAME = "syncLogOikView";

export default defineComponent({
  name: "JournalView",
  components: {
    Filters,
    ScrollPanel,
    InfoElements,
    TableSettings,
    ExportButton,
    TableHeader,
    AutoRefreshButton,
    Table,
    SortPanelContainer,
    InfiniteScroll,
  },
  data(): IData {
    return {
      isSelectAllElement: false,
      tableHeaders,
      convertDataItemToTableView,
      keyFieldsArray: ["code", "lastRefreshDate"],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "journalCells",
      "data",
      "totalLength",
      "infiniteId",
      "fieldFilters",
      "isTableLoading",
      "isOpenFilters",
      "autorefresh",
      "isLoadingChangeAutorefresh",
    ]),
    isCanExport(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_LOG_EXPORT);
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, { getEventList: "getItems" }),
    ...mapActions(SECTION_NAME, ["refreshScroll", "addFilter"]),
    ...mapActions(SECTION_NAME, ["setIsOpenFilters", "setAutorefresh", "getOikLogAllExportData"]),
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setJournalCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useRefreshTable(root, SECTION_NAME, 15000, "data", "refreshJournalList");

    return {
      sectionName,
    };
  },
});
