import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/fields";

export const tableHeaders = [
  {
    text: "№",
    tooltip: "Номер по порядку",
    defaultWidth: 40,
    value: fields.NUMBER,
    isHiddenByDefault: false,
    isLink: false,
    isSorted: false,
    isHandle: false,
    id: uuid(),
    slotName: "number",
  },
  {
    text: "Дата последнего обновления",
    tooltip: "Дата последнего обновления",
    defaultWidth: 200,
    value: fields.LAST_SYNC,
    isHiddenByDefault: false,
    isLink: false,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Код ОИК",
    tooltip: "Код ОИК в ЦХЭД",
    defaultWidth: 160,
    value: fields.OIK_CODE,
    isHiddenByDefault: false,
    isLink: false,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Наименование ОИК",
    tooltip: "Наименование ОИК в ЦХЭД",
    defaultWidth: 240,
    value: fields.OIK_NAME,
    isHiddenByDefault: false,
    isLink: false,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Статус",
    tooltip: "Статус события",
    defaultWidth: 200,
    value: fields.STATUS_RUSSIAN_TEXT,
    sortField: "status",
    isHiddenByDefault: false,
    isLink: false,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
];
